import React, { useState, useEffect } from 'react';

const sunIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-sun">
        <circle cx="12" cy="12" r="5"></circle>
        <path d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42"></path>
    </svg>
);

const moonIcon = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="feather feather-moon">
        <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
    </svg>
);

export default function DarkMode() {
    const [theme, setTheme] = useState('light');
    const lightTheme = 'light';
    const darkTheme = 'dark';

    useEffect(() => {
        const body = document.querySelector('body');
        const darkQuery = window?.matchMedia('(prefers-color-scheme: dark)');
        if (localStorage) {
            const storageTheme = localStorage.getItem('theme');
            if (storageTheme === lightTheme || storageTheme === darkTheme) {
                body.classList.add(storageTheme);
                setTheme(storageTheme);
            } else if (darkQuery?.matches) {
                body.classList.add(darkTheme);
                setTheme(darkTheme);
            } else body.classList.add(lightTheme);
        }
    }, []);

    const switchTheme = () => {
        const body = document.querySelector('body');
        const themeColor = document.querySelector('head > meta[name="theme-color"]');

        if (theme === darkTheme) {
            themeColor?.setAttribute('content', '#ffffff');
            body.classList.replace(darkTheme, lightTheme);
            localStorage.setItem('theme', 'light');
            setTheme('light');
        } else {
            themeColor?.setAttribute('content', '#000000');
            body.classList.replace(lightTheme, darkTheme);
            localStorage.setItem('theme', 'dark');
            setTheme('dark');
        }
    };

    return (
        <button aria-label={theme === darkTheme ? 'Dark Mode' : 'Light Mode'} title={theme === darkTheme ? 'Dark Mode' : 'Light Mode'} onClick={e => switchTheme(e)}>
            {theme === darkTheme ? moonIcon : sunIcon}
        </button>
    );
}
