import React from 'react';
import Link from 'next/link';
// import Image from 'next/image';
import { motion } from 'framer-motion';

export default function Logo() {
    // @refresh reset
    return (
        <motion.div
            animate={{
                opacity: 1
            }}
            initial={{
                opacity: 0
            }}
            transition={{
                duration: 0.5
            }}
            className="logo h-14 w-14 py-2">
            <Link title="Home - Jews for Jesus" hrefLang="en" href="/" as="/" passHref>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 305.62 304.91">
                    <path
                        className="dark:fill-white"
                        d="M92.37,208.36H79.16a8.29,8.29,0,0,0,1.69-5.53v-78a7.19,7.19,0,0,0-1.69-4.53H123.3v8.29a16.08,16.08,0,0,0-7.31-2.14H99.33c-4,.08-6.93.13-9,.13v30.06H123.3v8.17a16.66,16.66,0,0,0-7.13-2H99.51c-4,.09-7,.12-8.88.12q0,4.66-.13,14c-.08,6.2-.13,10.82-.13,13.83-.08,7.22-.08,10.31,0,9.3Q90.61,206.74,92.37,208.36Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M233.44,208.36H220.23a8.29,8.29,0,0,0,1.7-5.53v-78a7.2,7.2,0,0,0-1.7-4.53h13.7q16.2,0,22.61,2.76,10.68,4.55,10.67,18.36a18.39,18.39,0,0,1-7.28,15.26,23.67,23.67,0,0,1-16.81,5.09,25.77,25.77,0,0,1,5.17,4.79l28.29,35a33.58,33.58,0,0,0,6.92,6.79H270l-38.25-49c0,3.35,0,8.39-.12,15.09s-.13,11.74-.13,15.09q0,11.58,0,10.07C231.61,204.42,232.27,207.36,233.44,208.36Zm6.39-82.12q-2.79,0-8.38.51V157.2a68.82,68.82,0,0,0,7.24.39q19.41,0,19.42-15.93Q258.11,126.24,239.83,126.24Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M48.58,26.82H60.46q-1.73,1.47-1.72,6V97.43q0,14.37-2.37,19.35c-1.89,3.92-14.36,15.88-22,20.48l-1.36-2c4.45-3,14.83-12.9,15.75-14.25,1.22-2,1.84-5.36,1.84-10.18V30.89A5.4,5.4,0,0,0,48.58,26.82Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M39.61,220.89H51.49q-1.73,1.47-1.72,6v64.63q0,14.37-2.37,19.35c-1.89,3.93-14.36,15.88-22,20.48l-1.36-2c4.45-3,14.82-12.89,15.74-14.25q1.84-2.94,1.85-10.18V225A5.41,5.41,0,0,0,39.61,220.89Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M75.08,26.82H114.9v7.45a14.84,14.84,0,0,0-6.54-1.93h-15c-3.61.08-6.28.11-8,.11v27H114.9v7.36A14,14,0,0,0,108.36,65h-15q-5.41.12-8,.12V99.5h26.29a28,28,0,0,0,11.4-2.13l-2.49,8.6H75.08a6.9,6.9,0,0,0,1.72-5V30.89A6,6,0,0,0,75.08,26.82Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M140.19,26.82a5.46,5.46,0,0,0-1,3.39q0,2.49,9.05,26.56,5.53,14.82,11.08,29.63l16.4-43.87c-1-2.78-2-5.54-2.94-8.25a23.33,23.33,0,0,0-4.52-7.46h12.89a5.56,5.56,0,0,0-.9,2.94q0,1.69,9.39,27.24,5.53,14.7,11,29.4l19-51.67a16.41,16.41,0,0,0,1.24-5,8.61,8.61,0,0,0-.68-2.94h10.75a17.57,17.57,0,0,0-4.07,6.57l-27.35,73.92L178.87,51.45l-20.81,55.86-27.15-73.8a16.49,16.49,0,0,0-4.41-6.69Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M277.09,36.19A23.66,23.66,0,0,0,262,31a21.16,21.16,0,0,0-11.86,3.17,11.57,11.57,0,0,0-5.53,10.28q0,6.21,10.39,12.43,9.49,5.66,19.1,11.42,10.38,7.58,10.39,17.07,0,11.65-9.95,17.08-7.82,4.29-20.48,4.3a72.1,72.1,0,0,1-17.75-2.15v-8.8q7.68,5.43,18.43,5.43,8.37,0,13.69-3.38,6.22-4.07,6.22-11.94,0-7-10.07-13.18-9.28-5.4-18.66-10.81Q235.81,54.7,235.8,45.36q0-10.14,8.59-15.09,6.8-3.84,17.88-3.83a74.54,74.54,0,0,1,14.82,1.66Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M66.49,221.05h39.82v7.46a14.75,14.75,0,0,0-6.54-1.93h-15c-3.61.08-6.27.11-8,.11v27h29.54v7.35a14,14,0,0,0-6.54-1.81h-15c-3.61.08-6.27.11-8,.11v34.37h26.29a28.21,28.21,0,0,0,11.4-2.13l-2.49,8.6H66.49a7,7,0,0,0,1.71-5V225.12A6.07,6.07,0,0,0,66.49,221.05Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M165.1,230.43A23.64,23.64,0,0,0,150,225.22a21.28,21.28,0,0,0-11.87,3.17,11.59,11.59,0,0,0-5.53,10.29q0,6.23,10.39,12.43,9.49,5.67,19.1,11.43,10.4,7.57,10.4,17.07,0,11.65-10,17.07Q154.7,301,142,301a72.1,72.1,0,0,1-17.75-2.15V290q7.7,5.43,18.43,5.42,8.37,0,13.69-3.37,6.23-4.06,6.22-11.95,0-7-10.06-13.18-9.28-5.4-18.67-10.81-10.06-7.2-10.07-16.55,0-10.14,8.6-15.1,6.78-3.83,17.87-3.83a74.54,74.54,0,0,1,14.82,1.66Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M234.54,221.05H246.3q-1.7,1.57-1.7,6v41q0,32.9-30.19,32.9-13.11,0-21-7-8.25-7.23-8.81-20.35c-.09-1.73-.13-5.23-.13-10.52q0-4.62.11-13.79t.11-13.68c0-6.17,0-8.74.12-7.68-.16-3.39-.79-5.69-1.92-6.9h11.87c-1.17,1-1.75,2.61-1.75,4.87q0,4.89-.12,14.86t-.11,15q0,18.7,2.26,25.51,4.53,13.26,19.47,13.26a23,23,0,0,0,12-3.17,18.06,18.06,0,0,0,8.39-9.75q1.35-4,1.35-13,0-4.87.12-14.62t.11-14.63q0-11.1.11-9.63C236.35,225.14,235.67,222.26,234.54,221.05Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M298.48,230.43a23.64,23.64,0,0,0-15.13-5.21,21.22,21.22,0,0,0-11.86,3.17A11.58,11.58,0,0,0,266,238.68q0,6.23,10.39,12.43,9.5,5.67,19.1,11.43,10.4,7.57,10.4,17.07,0,11.65-10,17.07-7.81,4.31-20.47,4.3a72.18,72.18,0,0,1-17.76-2.15V290q7.69,5.43,18.44,5.42,8.37,0,13.69-3.37Q296,288,296,280.14q0-7-10.07-13.18-9.29-5.4-18.66-10.81Q257.19,249,257.2,239.6q0-10.14,8.59-15.1,6.79-3.83,17.88-3.83a74.52,74.52,0,0,1,14.81,1.66Z"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="fill-lightBlue dark:fill-lightBlue"
                        d="M168.58,117.33l-39.21,67.73h78.16Zm-39.09,23,78.13,0L168.56,207.8l-39.07-67.48"
                        transform="translate(-24 -26.44)"
                    />
                    <path
                        className="dark:fill-white"
                        d="M329.62,290.51a8.91,8.91,0,1,1-2.6-6.27A8.55,8.55,0,0,1,329.62,290.51Zm-.72,0a7.89,7.89,0,0,0-2.4-5.77,8.1,8.1,0,0,0-11.51,0,8.19,8.19,0,0,0,5.77,14,7.78,7.78,0,0,0,5.74-2.41A7.92,7.92,0,0,0,328.9,290.52ZM326,294.67l-1.15,1-1.36-1.45c-.84-.91-1.85-2-3-3.32v-.25a5.09,5.09,0,0,0,.82-.31,3,3,0,0,0,.76-.5,2.36,2.36,0,0,0,.46-.54,1.73,1.73,0,0,0,.18-.88,1.23,1.23,0,0,0-.28-.81,2,2,0,0,0-.76-.53,3.37,3.37,0,0,0-.79-.18,8.37,8.37,0,0,0-.89-.05h-.72c0,.71,0,1.43,0,2.17v1.47c0,.76,0,1.74,0,2.93s0,1.83,0,1.93h-1.59c0-.11,0-.69,0-1.75s0-2.08,0-3.06c0-.29,0-.8,0-1.55s0-1.89,0-3.44l1,0c.64,0,1.11,0,1.41,0a11.25,11.25,0,0,1,1.27.08,4.79,4.79,0,0,1,1.2.31,3.09,3.09,0,0,1,1.25.95,2.38,2.38,0,0,1,.46,1.46,2.62,2.62,0,0,1-.42,1.43,4.25,4.25,0,0,1-1.33,1.25v.1c.75.86,1.5,1.65,2.24,2.37Z"
                        transform="translate(-24 -26.44)"
                    />
                </svg>
            </Link>
        </motion.div>
    );
}
