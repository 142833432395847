import { useEffect, useState } from 'react';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import useScrollDirection from '@/hooks/useScrollDirection';
import useScrollPosition from '@/hooks/useScrollPosition';
import { useRouter } from 'next/router';
import { checkIfStringStartsWith } from '@/utils/checkIfStringStartsWith';
import DarkMode from '@/components/DarkMode';
import XIcon from 'src/svgs/XIcon';
import SearchIcon from 'src/svgs/SearchIcon';
import Logo from '../svgs/Logos/Logo';
import WhiteLogo from '../svgs/Logos/WhiteLogo';
import Container from './Container';
import RotatingLinks from './RotatingLinks';
import MakeMyGift from './MakeMyGift';

const Menu = dynamic(() => import('./AnimatedNav/index'));
const QuickSearch = dynamic(() => import('./QuickSearch'), { ssr: false });

function LogoSwitch({ isTransparentHeader }) {
    const scrollPosition = useScrollPosition();
    let DIV = <Logo className="transition-all duration-100" />;
    if (isTransparentHeader && scrollPosition < 10) DIV = <WhiteLogo />;
    else DIV = <Logo className="transition-all duration-100" />;

    return DIV;
}

export default function Header({ isTransparentHeader, hideMakeMyGift, newsletter }) {
    const router = useRouter();
    const [searchFormShow, setSearchFormShow] = useState(false);
    const [headerShadow, setHeaderShadow] = useState('');
    const [transHeaderActive, setTransHeaderActive] = useState(false);
    const scrollDirection = useScrollDirection();
    const headerLogoClasses = transHeaderActive ? 'pt-3' : 'w-1/6';
    const learnLinks = ['/learn', '/learning', '/answers', '/jewish-resources'];
    const storyLinks = ['/our-stories', '/stories'];
    const isLearnLink = checkIfStringStartsWith(router?.asPath, learnLinks);
    const isStoryLink = checkIfStringStartsWith(router?.asPath, storyLinks);

    const searchFormToggle = () => {
        setSearchFormShow(!searchFormShow);
    };

    useEffect(() => {
        const header = document.querySelector('#header-container');
        const body = document.querySelector('body');

        if (searchFormShow) {
            body.classList.add('overflow-hidden');
        } else {
            body.classList.remove('overflow-hidden');
        }

        const transparentHeaderHandler = () => {
            const top = (window.pageYOffset || document.scrollTop) - (document.clientTop || 0);
            setHeaderShadow(!transHeaderActive ? 'bg-white dark:bg-darkModeBg shadow-lg shadow-black' : '');

            if (!isTransparentHeader) {
                header?.classList.remove('transparent-header');
                setTransHeaderActive(false);
                return null;
            }

            // Transparent headers only appear at the very top of the page
            if (top >= 10) {
                header?.classList.remove('transparent-header');
                header?.classList.add('bg-white', 'dark:bg-darkModeBg');
                setTransHeaderActive(false);
            } else {
                header?.classList.add('transparent-header');
                header?.classList.remove('bg-white', 'dark:bg-darkModeBg');
                setTransHeaderActive(true);
            }
        };

        document?.addEventListener('scroll', transparentHeaderHandler);

        transparentHeaderHandler();

        return () => {
            document.removeEventListener('scroll', transparentHeaderHandler);
        };
    }, [transHeaderActive, isTransparentHeader, searchFormShow]);

    return (
        <>
            <header
                id={`header-container`}
                className={cx([
                    'z-50 w-full px-3 pb-2 pt-1 lg:px-0',
                    headerShadow,
                    isTransparentHeader ? 'transparent-header mb-[-80px]' : 'bg-white transition-all duration-200 dark:bg-darkModeBg',
                    'sticky',
                    scrollDirection === 'down' ? '-top-32' : 'top-0'
                ])}>
                <Container header transHeader={isTransparentHeader} className={cx(['flex'])}>
                    <div className={cx([`header-logo-container h-16 md:w-1/6`, headerLogoClasses])}>
                        <div className={!transHeaderActive && 'h-[50px] w-[50px]'}>
                            <LogoSwitch transHeaderActive={transHeaderActive} isTransparentHeader={isTransparentHeader} />
                        </div>
                    </div>
                    <div className="menu-search-container flex w-5/6 flex-row justify-end md:w-5/6">
                        <div className="header-links-container my-auto mr-3">
                            <RotatingLinks isLearnLink={isLearnLink} isStoryLink={isStoryLink} />
                        </div>
                        <div id="toggler" className="order-1 flex items-center justify-center p-2">
                            <DarkMode />
                        </div>
                        {!isLearnLink && !isStoryLink && !hideMakeMyGift && (
                            <div className={cx(['menu-gift order-0 hidden items-center justify-center vsm:flex', !newsletter && 'p-2'])}>
                                <MakeMyGift newsletter={newsletter} />
                            </div>
                        )}
                        <div className={cx(['menu-search flex items-center justify-center p-2'])}>
                            <div className="search-icon-container relative">
                                <a title="Search" onClick={searchFormToggle} onKeyDown={searchFormToggle} role="presentation" className="cursor-pointer text-black">
                                    <SearchIcon className="block h-6 w-6 dark:text-white" />
                                </a>
                                {searchFormShow && (
                                    <div className="fixed right-0 top-0 z-[60] h-screen w-full">
                                        <div className="search-form fixed right-0 top-0 z-[60] h-screen w-full rounded-sm bg-white pb-5 text-white drop-shadow-lg dark:bg-darkModeBg ">
                                            <div className="relative mx-auto max-w-[780px]">
                                                <div
                                                    className="close-button absolute right-10 top-6 z-[60] cursor-pointer text-[#999] dark:text-white"
                                                    onClick={searchFormToggle}
                                                    role="presentation"
                                                    onKeyDown={searchFormToggle}>
                                                    <XIcon className="block h-6 w-6 dark:text-black" />
                                                </div>
                                            </div>
                                            <QuickSearch searchFormToggle={searchFormToggle} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="flex w-10 items-center justify-end p-2">
                            <Menu />
                        </div>
                    </div>
                </Container>
            </header>
            <style jsx>{`
                button.dark > svg {
                    color: white;
                }
                button.light > svg {
                    color: black;
                }
            `}</style>
        </>
    );
}
