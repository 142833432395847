import Link from 'next/link';

export default function WhiteLogo() {
    return (
        <Link title="Home - Jews for Jesus" hrefLang="en" href="/" as="/">
            <div className="my-1.5 w-[115px] transition-all duration-100 md:mx-1.5">
                <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="white" viewBox="0 0 237 237">
                    <defs>
                        <clipPath id="clippath">
                            <rect className="cls-1" x="9.9" y="11.98" width="217.19" height="213.05" />
                        </clipPath>
                    </defs>
                    <g id="Artwork_2">
                        <g className="cls-2">
                            <g>
                                <path
                                    className="cls-3"
                                    d="M58.49,139.09h-9.39c.81-.93,1.2-2.23,1.2-3.86v-54.47c0-1.12-.4-2.16-1.2-3.16h31.38v5.79c-1.31-.82-3.05-1.31-5.2-1.49h-11.84c-2.81,.06-4.93,.09-6.37,.09v21.01h23.4v5.71c-1.31-.76-3-1.23-5.07-1.41h-11.83c-2.85,.06-4.95,.09-6.32,.09,0,2.17-.03,5.42-.09,9.76-.06,4.33-.09,7.56-.09,9.66-.06,5.04-.06,7.2,0,6.5,.11,3.11,.59,5.04,1.42,5.8"
                                />
                                <path
                                    className="cls-3"
                                    d="M158.74,139.09h-9.39c.8-.93,1.2-2.23,1.2-3.86v-54.47c0-1.12-.4-2.16-1.2-3.16h9.73c7.67,0,13.03,.65,16.07,1.93,5.06,2.12,7.59,6.38,7.59,12.82,0,4.45-1.73,8.01-5.17,10.67-3.45,2.66-7.43,3.85-11.95,3.56,1.37,.94,2.59,2.06,3.67,3.35l20.11,24.44c1.91,2.28,3.54,3.87,4.92,4.74h-9.63l-27.18-34.27c0,2.34-.03,5.86-.09,10.55-.06,4.69-.09,8.2-.09,10.54,.02,5.4,.02,7.74,0,7.04,.11,3.39,.58,5.44,1.41,6.14m4.54-57.38c-1.32,0-3.31,.12-5.95,.36v21.28c1.74,.18,3.46,.27,5.14,.27,9.2,0,13.8-3.71,13.8-11.13s-4.33-10.78-12.99-10.78"
                                />
                                <path
                                    className="cls-3"
                                    d="M27.37,12.24h8.44c-.82,.69-1.22,2.08-1.22,4.19V61.58c0,6.69-.56,11.2-1.69,13.52-1.34,2.74-10.2,11.09-15.66,14.31l-.96-1.43c3.16-2.11,10.53-9.01,11.19-9.96,.87-1.37,1.31-3.74,1.31-7.12V15.09c0-1.06-.47-2-1.41-2.85"
                                />
                                <path
                                    className="cls-3"
                                    d="M21,147.85h8.44c-.82,.68-1.22,2.08-1.22,4.19v45.16c0,6.7-.56,11.2-1.69,13.52-1.34,2.74-10.2,11.09-15.66,14.31l-.97-1.43c3.16-2.11,10.53-9.01,11.19-9.96,.87-1.37,1.31-3.74,1.31-7.11v-55.83c0-1.05-.47-2-1.4-2.85"
                                />
                                <path
                                    className="cls-3"
                                    d="M46.21,12.24h28.3v5.21c-1.23-.74-2.78-1.19-4.65-1.35h-10.66c-2.57,.05-4.46,.08-5.69,.08v18.88h20.99v5.14c-1.23-.74-2.78-1.16-4.65-1.27h-10.66c-2.57,.05-4.46,.08-5.69,.08v24.01h18.68c3.1,0,5.8-.49,8.1-1.49l-1.77,6.01H46.21c.81-.84,1.22-2,1.22-3.48V15.08c0-1-.41-1.94-1.22-2.84"
                                />
                                <path
                                    className="cls-3"
                                    d="M92.47,12.24c-.48,.63-.72,1.42-.72,2.37,0,1.16,2.14,7.35,6.43,18.56,2.62,6.9,5.25,13.81,7.87,20.71l11.66-30.66c-.7-1.95-1.4-3.87-2.09-5.76-.91-2.21-1.98-3.95-3.21-5.22h9.17c-.43,.69-.64,1.37-.64,2.05,0,.79,2.23,7.14,6.67,19.04,2.62,6.85,5.22,13.7,7.79,20.55l13.49-36.11c.59-1.58,.88-2.74,.88-3.48,0-.53-.16-1.21-.48-2.05h7.64c-1.18,1.11-2.15,2.64-2.89,4.59l-19.44,51.65-14.63-39.03-14.79,39.03-19.29-51.57c-.64-1.79-1.69-3.36-3.14-4.67h9.73Z"
                                />
                                <path
                                    className="cls-3"
                                    d="M189.76,18.79c-3.11-2.42-6.69-3.64-10.76-3.64-3.31,0-6.13,.74-8.42,2.21-2.62,1.69-3.93,4.08-3.93,7.19,0,2.89,2.46,5.79,7.39,8.68,4.49,2.64,9.02,5.29,13.57,7.98,4.92,3.53,7.39,7.51,7.39,11.93,0,5.42-2.36,9.4-7.07,11.93-3.7,2-8.55,3-14.55,3-4.39,0-8.6-.5-12.62-1.5v-6.15c3.64,2.53,8.01,3.79,13.1,3.79,3.96,0,7.2-.78,9.72-2.36,2.95-1.89,4.42-4.67,4.42-8.34,0-3.25-2.38-6.32-7.16-9.21-4.39-2.52-8.81-5.04-13.26-7.55-4.77-3.36-7.15-7.21-7.15-11.57,0-4.72,2.04-8.23,6.11-10.54,3.21-1.78,7.45-2.67,12.7-2.67,3.16,0,6.67,.38,10.53,1.16v5.66Z"
                                />
                                <path
                                    className="cls-3"
                                    d="M40.1,147.96h28.3v5.21c-1.23-.74-2.78-1.19-4.64-1.35h-10.66c-2.57,.05-4.46,.08-5.69,.08v18.89h20.99v5.14c-1.23-.74-2.78-1.15-4.64-1.27h-10.66c-2.57,.05-4.46,.08-5.69,.08v24.01h18.68c3.1,0,5.8-.49,8.1-1.48l-1.77,6.01H40.1c.81-.84,1.22-2,1.22-3.47v-49c0-1-.41-1.94-1.22-2.84"
                                />
                                <path
                                    className="cls-3"
                                    d="M110.18,154.51c-3.11-2.42-6.69-3.64-10.76-3.64-3.32,0-6.13,.74-8.43,2.22-2.62,1.69-3.93,4.08-3.93,7.19,0,2.9,2.46,5.79,7.39,8.69,4.5,2.64,9.02,5.29,13.57,7.98,4.92,3.53,7.39,7.51,7.39,11.93,0,5.43-2.36,9.41-7.08,11.93-3.7,2.01-8.55,3.01-14.55,3.01-4.39,0-8.6-.5-12.62-1.5v-6.15c3.64,2.53,8.01,3.79,13.1,3.79,3.96,0,7.21-.79,9.73-2.36,2.95-1.89,4.42-4.67,4.42-8.35,0-3.25-2.38-6.32-7.15-9.21-4.39-2.52-8.81-5.04-13.26-7.56-4.77-3.36-7.16-7.21-7.16-11.57,0-4.72,2.03-8.24,6.11-10.55,3.21-1.78,7.45-2.68,12.7-2.68,3.16,0,6.67,.38,10.53,1.16v5.65Z"
                                />
                                <path
                                    className="cls-3"
                                    d="M159.52,147.96h8.36c-.81,.74-1.21,2.13-1.21,4.18v28.68c0,15.32-7.15,22.99-21.46,22.99-6.21,0-11.19-1.63-14.94-4.9-3.91-3.37-6-8.11-6.27-14.22-.06-1.21-.09-3.65-.09-7.35,0-2.16,.02-5.36,.08-9.64,.05-4.26,.08-7.45,.08-9.56,0-4.32,.03-6.11,.08-5.37-.11-2.37-.56-3.97-1.36-4.82h8.44c-.83,.69-1.24,1.82-1.24,3.41,0,2.27-.03,5.73-.08,10.38-.05,4.64-.08,8.13-.08,10.45,0,8.71,.54,14.65,1.61,17.82,2.14,6.18,6.76,9.27,13.83,9.27,3.17,0,6.01-.74,8.53-2.22,3-1.74,4.99-4.01,5.96-6.81,.64-1.85,.96-4.88,.96-9.11,0-2.27,.03-5.67,.08-10.22,.05-4.55,.08-7.95,.08-10.22,0-5.18,.02-7.42,.08-6.74-.16-3.17-.64-5.17-1.44-6.02"
                                />
                                <path
                                    className="cls-3"
                                    d="M204.97,154.51c-3.11-2.42-6.69-3.64-10.76-3.64-3.32,0-6.13,.74-8.43,2.22-2.62,1.69-3.94,4.08-3.94,7.19,0,2.9,2.46,5.79,7.39,8.69,4.5,2.64,9.02,5.29,13.57,7.98,4.92,3.53,7.39,7.51,7.39,11.93,0,5.43-2.36,9.41-7.08,11.93-3.7,2.01-8.55,3.01-14.55,3.01-4.39,0-8.6-.5-12.62-1.5v-6.15c3.64,2.53,8.01,3.79,13.1,3.79,3.97,0,7.21-.79,9.73-2.36,2.94-1.89,4.42-4.67,4.42-8.35,0-3.25-2.39-6.32-7.15-9.21-4.39-2.52-8.82-5.04-13.26-7.56-4.77-3.36-7.16-7.21-7.16-11.57,0-4.72,2.03-8.24,6.11-10.55,3.22-1.78,7.45-2.68,12.7-2.68,3.16,0,6.67,.38,10.53,1.16v5.65Z"
                                />
                                <path className="cls-3" d="M112.65,75.49l-27.86,47.33h55.55l-27.68-47.33Zm-27.78,16.06l55.52,.03-27.76,47.12-27.76-47.15" />
                                <path
                                    className="cls-3"
                                    d="M227.1,196.49c0,1.7-.62,3.17-1.86,4.38-1.24,1.22-2.72,1.83-4.44,1.83s-3.22-.61-4.46-1.84c-1.24-1.23-1.86-2.68-1.86-4.37s.63-3.18,1.88-4.39c1.25-1.21,2.73-1.82,4.45-1.82s3.21,.61,4.44,1.83c1.24,1.22,1.85,2.68,1.85,4.38m-.51,0c0-1.56-.57-2.9-1.7-4.03-1.13-1.12-2.5-1.68-4.08-1.68s-2.97,.56-4.1,1.68c-1.13,1.12-1.7,2.46-1.7,4.03s.57,2.91,1.7,4.04c1.13,1.12,2.5,1.68,4.1,1.68s2.95-.56,4.08-1.68c1.13-1.13,1.7-2.47,1.7-4.04m-2.05,2.9l-.81,.71s-.36-.38-.97-1.02c-.6-.63-1.31-1.41-2.14-2.32v-.17c.17-.04,.36-.11,.58-.22,.22-.1,.4-.22,.54-.34,.13-.12,.24-.24,.32-.38,.08-.14,.13-.34,.13-.62,0-.22-.07-.4-.2-.56-.13-.16-.31-.28-.54-.37-.18-.06-.37-.1-.56-.13-.2-.02-.41-.03-.63-.03-.06,0-.15,0-.28,0-.12,0-.2,0-.23,0,0,.5-.01,1-.01,1.52,0,.52,0,.86,0,1.02,0,.53,0,1.21,.02,2.04,0,.83,.01,1.28,.01,1.35h-1.13c0-.07,0-.48,.02-1.22,0-.74,.02-1.45,.02-2.14,0-.2,0-.56,0-1.08,0-.52-.01-1.32-.02-2.41,.03,0,.28,0,.73-.01,.45,0,.79-.01,1-.01,.29,0,.59,.02,.9,.06,.31,.04,.59,.11,.85,.22,.37,.16,.66,.38,.89,.66,.22,.28,.33,.62,.33,1.02,0,.36-.1,.7-.3,1-.2,.32-.51,.61-.95,.87v.07c.54,.6,1.07,1.15,1.59,1.66,.53,.51,.81,.78,.85,.82"
                                />
                            </g>
                        </g>
                    </g>
                </svg>
            </div>
        </Link>
    );
}
